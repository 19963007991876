@import '~antd/dist/antd.css';

.mk-header-data {
    padding: 3px;
    font-size: 18px;
}

.login-form {
    max-width: 300px;
    margin-left: 10%;
}


.entitlement-confirm-form {
    border: 2px solid red;
}

.footer-links {
    display: block;
    margin-left: auto;
    margin-right: auto;  
}

html, body {
    margin: 0;
    padding: 0;
    height: 100%;

    font-family: 'Source Sans Pro', sans-serif;

    box-sizing:border-box;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
}

.ant-layout {
    /*
    min-height: 100vh;
    overflow: hidden;
    display: block;
    position: relative;
    padding-bottom: 80px;
    */
    box-sizing:border-box;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
}

.ant-layout-header {
    line-height: 0px;
    padding: 5px 25px;
    margin: 10px 0px;
    height: 80px;
    background: #f0f2f5;
    border-bottom: 2px solid grey;
}

.ant-layout-content {
    margin: 10px;
    margin-bottom: 100px;
}

.ant-layout-footer {
    position: fixed; 
    bottom: 0;
    width: 100%;

    color: #fff;
    background: #000;

    padding: 5px 50px;
}

.ant-layout-footer a {
    text-decoration: none;
    font-size: 14px;
    font-weight: 300;

    cursor: pointer;
    color: #fff !important;

    text-align: center!important;
}

.ant-layout-footer p {
    font-size: 12px;
    font-weight: 300;
    margin-top: 20px;
    margin-bottom: 20px;
}

.text-center {
    text-align: center!important;
}

.nav-bar {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 16px;
    background-color: rgba(0,0,0,.0);
}

